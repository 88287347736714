
import {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined
} from "@ant-design/icons-vue";
import {
    createVNode
} from "vue";
import Editor from "./editor";

export default {
    name: "BasicShipper",
    components: {
        PlusOutlined,
        DeleteOutlined,
        Editor,
        CheckCircleOutlined
    },
    data() {
        return {
            url: "/admin/shipper/list",
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 搜索表单是否展开
            searchExpand: true
        };
    },
    computed: {
        columns() {
            return [{
                    key: "index",
                    width: 48,
                    customRender: ({
                            index
                        }) =>
                        this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t("登录账号"),
                    dataIndex: "user_account",
                    width: 120,
                    sorter: true
                },
                {
                    title: this.$t("名称"),
                    dataIndex: "name",
                    width: 200,
                    sorter: true,
                    slots: {
                        customRender: "name"
                    }
                },
                {
                    title: this.$t("地址"),
                    dataIndex: "address1",
                    width: 300,
                    sorter: true,
                    ellipsis: true
                    // className: "max-address",
                    // slots: {
                    //     customRender: "address"
                    // }
                },
                {
                    title: this.$t("城市"),
                    dataIndex: "city",
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t("省/州/地区"),
                    dataIndex: "state_code",
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t("邮编"),
                    dataIndex: "postal_code",
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t("国家/地区"),
                    dataIndex: "country_code",
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t("电话号码"),
                    dataIndex: "phone_number",
                    width: 130,
                    sorter: true
                },
                {
                    title: this.$t("状态"),
                    dataIndex: "confirm",
                    width: 90,
                    sorter: true,
                    slots: {
                        customRender: "status"
                    }
                },
                {
                    title: this.$t('启用'),
                    dataIndex: 'enabled',
                    width: 100,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'enabled'
                    }
                },
                {
                    title: this.$t("创建时间"),
                    dataIndex: "created",
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t("操作"),
                    key: "action",
                    width: 180,
                    fixed: 'right',
                    slots: {
                        customRender: "action"
                    }
                }
            ]
        }
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /**
         * 同步资料
         */
        sync_data(row) {
            const hide = this.$message.loading("请求中...", 0);

            this.$http
                .get(`/admin/shipper/sync/${row.id}`)
                .then(res => {
                    hide();

                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    hide();
                    this.$message.error(e.message);
                });
        },
        /**
         * 确认地址
         */
        confirm(row) {
            const hide = this.$message.loading("请求中...", 0);

            this.$http
                .get(`/admin/shipper/sync/${row.id}`)
                .then(res => {
                    if (res.data.code === 0) {
                        this.$http
                            .get(
                                `/admin/shipper/confirm/${row.id}?confirm_result=1`
                            )
                            .then(res => {
                                hide();

                                if (res.data.code === 0) {
                                    this.$message.success(res.data.msg);
                                    this.reload();
                                } else {
                                    this.$message.error(res.data.msg);
                                }
                            })
                            .catch(e => {
                                hide();
                                this.$message.error(e.message);
                            });
                    } else {
                        hide();
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    hide();
                    this.$message.error(e.message);
                });
        },
        /**
         * 驳回地址
         */
        $return(row) {
            const hide = this.$message.loading("请求中...", 0);

            this.$http
                .get(`/admin/shipper/confirm/${row.id}?confirm_result=0`)
                .then(res => {
                    hide();

                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    hide();
                    this.$message.error(e.message);
                });
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading("share.requesting", 0);

            this.$http
                .post("/admin/shipper/delete", {
                    ids: [row.id]
                })
                .then(res => {
                    hide();
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    hide();
                    this.$message.error(e.message);
                });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error("请至少选择一条数据");
            }

            this.$confirm({
                title: this.$t("提示"),
                content: this.$t("确定要删除选中的数据吗?"),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading("请求中...", 0);

                    this.$http
                        .post("/admin/shipper/delete", {
                            ids: this.selection.map(d => d.id)
                        })
                        .then(res => {
                            hide();
                            if (res.data.code === 0) {
                                this.$message.success(res.data.msg);
                                this.reload();
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(e => {
                            hide();
                            this.$message.error(e.message);
                        });
                }
            });
        },
        /* 修改状态 */
        editState(checked, row, field) {
            this.$http.get(`/admin/shipper/state/${row.id}?${field}=${(checked ? 1 : 2)}`).then(res => {
                if (res.data.code === 0) {
                    row[field] = checked;
                    /*
                    checked && (field === 'is_default') && this.$refs.table.data.forEach((record) => {
                        if (record.id != row.id) {
                            record[field] = !checked;
                        }
                    });
                    */
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
    }
};
